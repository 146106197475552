import _ from 'lodash';
import { useMemo } from 'react';
import { Viz } from '../../VizUtil';
import { ChartSpecs } from '../../ChartSpecs';
import { useQuery } from '@apollo/client';
import { VizQueries } from '../../../common/graphql';
import {
  useOpenVizSelector,
  useVizOptionSelector,
} from '../../../common/redux/selectors/viz-selector.hook';
import { ILinkToReport } from '../../viz-redirect';
import { IToggle, IViz } from '../../interfaces';
import { useAccount } from '../../../common/utilities/account';
import { useSelector } from 'react-redux';

const isEnableReportLink = (_customFormatToggles: IToggle[]) => {
  const enableReportLinkToggle: IToggle = _.find(_customFormatToggles, {
    key: 'enableReportLink',
  });
  return !!enableReportLinkToggle?.on;
};

export const useReportLinkEnabled = (vizId: string) => {
  const linkToReport: ILinkToReport | {} = useVizOptionSelector({
    discoveryId: vizId,
    option: 'linkToReport',
    defaultValue: {},
  });
  const { data: { visualization: targetViz } = {} } = useQuery(
    VizQueries.GetVisualization,
    {
      skip: _.isNil((linkToReport as ILinkToReport)?.id),
      variables: {
        id: (linkToReport as ILinkToReport)?.id ?? '',
      },
    },
  );
  const viz = useOpenVizSelector({ discoveryId: vizId });
  const { isDashletUser } = useAccount();

  const { drillLinkDisabled = false } = useSelector(
    (state: any) => state.dashlet ?? {},
  );

  return (
    !drillLinkDisabled &&
    !isDashletUser &&
    shouldEnableReportLink(viz, [targetViz], linkToReport as ILinkToReport)
  );
};
export const shouldEnableReportLink = (
  viz: IViz,
  visualizations: IViz[],
  linkToReportProvided?: ILinkToReport,
) => {
  const customFormatToggles: IToggle[] = Viz.getCustomFormatTogglesFromViz(viz);
  const linkToReport =
    linkToReportProvided ??
    JSON.parse(_.get(viz, 'options.linkToReport', '{}') as string);

  const possibleLinkedReports = _.filter(visualizations ?? [], {
    chartType: ChartSpecs.pivot.id,
  });
  const linkedReportExists = _.some(possibleLinkedReports, {
    id: (linkToReport as ILinkToReport)?.id,
  });

  return (
    isEnableReportLink(customFormatToggles) &&
    !_.isEmpty(linkToReport) &&
    _.get(linkToReport, 'isValid', true) &&
    linkedReportExists
  );
};
/**
 * ShouldEnableReportLinkHOC is an HOC to use a custom React hook - useReportLinkEnabled
 * @param Component
 * @param vizId
 * @constructor
 */
export const ShouldEnableReportLinkHOC = (Component: any, vizId: string) => {
  return (props: any) => {
    const _vizId = vizId ?? props.vizId;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const enableReportLink = useReportLinkEnabled(_vizId);
    return <Component enableReportLink={enableReportLink} {...props} />;
  };
};

export const useOffscreenDimensions = ({ getOffscreenDimensionsAsync }) => {
  const { offscreenWidth, offscreenHeight } = getOffscreenDimensionsAsync();
  const dimensions = useMemo(() => ({ offscreenWidth, offscreenHeight }), [
    offscreenHeight,
    offscreenWidth,
  ]);
  return dimensions;
};

export const useOffscreenHeight = ({ getOffscreenDimensionsAsync }) => {
  const { offscreenHeight } = useOffscreenDimensions({
    getOffscreenDimensionsAsync,
  });
  return offscreenHeight;
};

export const useOffscreenWidth = ({ getOffscreenDimensionsAsync }) => {
  const { offscreenWidth } = useOffscreenDimensions({
    getOffscreenDimensionsAsync,
  });
  return offscreenWidth;
};

export const useVerticalAxisWrapper = ({
  getOffscreenDimensionsAsync,
  scrollPct,
}) => {
  const offscreenHeight = useOffscreenHeight({ getOffscreenDimensionsAsync });
  let scrollTop = Math.min(
    0,
    Math.max(-(offscreenHeight * scrollPct), -offscreenHeight),
  );
  scrollTop = _.isFinite(scrollTop) ? scrollTop : 0;

  const retVal = useMemo(() => ({ scrollPct, scrollTop }), [
    scrollPct,
    scrollTop,
  ]);
  return retVal;
};
