import { css, withTheme } from '@emotion/react';
import { useContext, PureComponent, ComponentClass, Component } from 'react';
import Discover from '../../common/redux/actions/DiscoverActions';
import MainActions from '../../common/redux/actions/MainActions';
import { Nav, NavItem } from '@sugar-discover/react-bootstrap-wrapper';
import {
  NavWithLeftMargin,
  NavItemFullWidth,
  DiscoveryToolbarNavbar,
  ActionItemRightNav,
  ToolbarRightNav,
  SaveDropdownStyles,
  ToolbarDropdownRoot,
} from './discovery-toolbar.styles';
import _, { includes } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { Labels, USE_FISCAL_REPORTING } from '../../common/Constants';
import { Viz } from '../VizUtil';
import { ChartSpecs } from '../ChartSpecs';
import {
  getIsReadOnlyUser,
  isAdmin,
  isPrivate,
  isUserOwnerOfDiscoveryViz,
} from '../../common/redux/selectors/AccountSelectors';
import { createSelector } from 'reselect';
import {
  VIZ_SELECTORS,
  getAllowedTabs,
  getConfigPanelDetail,
} from '../../common/redux/selectors/viz-selectors';
import { ACCOUNT_SELECTORS } from '../../common/redux/selectors/AccountSelectors';
import {
  IUserPreferencesMutationInputProps,
  UserPreferencesMutation,
} from '../../account/graphql';
import Account from '../../common/redux/actions/AccountActions';
import { messages } from '../../i18n';
import { FormGroup, FormControlLabel } from '@mui/material';
import { ISugarIconProps, InsightsIcon, DownChevron } from '../../icons';
import styled from '@emotion/styled';
import { IDiscoverEmotionTheme } from '../../common/emotion';

import {
  CheckIconSmall,
  FormatIcon,
  InfoIconLg,
  LayoutIcon,
  PinIcon,
  SaveIcon,
  ShareIcon,
  UndoIcon as UndoIconUnstyled,
  RedoIcon as RedoIconUnstyled,
  RefreshIcon as RefreshIconUnstyled,
} from '../../icons/icons/icons.component';
import classnames from 'classnames';
import { useExportToExcel } from '../../common/utilities/export-to-excel';
import { WorkerRefContext } from '../../common/utilities/web-worker';
import { IconDropdown } from '../../components/icon-dropdown';
import { DisableClickMenuItem } from '../../common/widgets/menu-items/disable-click-menu-item/disable-click-menu-item.component';
import Divider from '@mui/material/Divider';
import { ToastHOC } from '../../components/toast/toast-launcher';
import {
  IDiscoveryToolbarDispatchProps,
  IDiscoveryToolbarMapStateProps,
  IDiscoveryToolbarOwnProps,
  IDiscoveryToolbarProps,
  IDiscoveryToolbarState,
} from './discovery-toolbar.interfaces';
import moment from 'moment';
import { IRevision } from '../interfaces';
import { Switch } from '../../components/switch';

const ActionItemIconCss = css`
  padding-left: 4px;
  padding-right: 4px;
`;
const UndoIcon = props => (
  <UndoIconUnstyled css={ActionItemIconCss} {...props} />
);
const RedoIcon = props => (
  <RedoIconUnstyled css={ActionItemIconCss} {...props} />
);
const RefreshIcon = props => (
  <RefreshIconUnstyled css={ActionItemIconCss} {...props} />
);

const SaveIconWithPointerControl = styled(SaveIcon)`
  pointer-events: ${(
    props: Omit<ISugarIconProps, 'icon'> & { isSaveIconDisabled: boolean },
  ) => (props.isSaveIconDisabled ? 'none' : 'all')};
`;

class SaveAsDropdown extends PureComponent<{
  saveDisabled: boolean;
  onSave: () => void;
  onSaveVersion: () => void;
  onSaveAs: () => void;
  isNewViz: boolean;
  isDirty: boolean;
  isUserOwner: boolean;
  isReadOnlyUser: boolean;
  canSaveVersion: boolean;
}> {
  state;
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
  }
  render() {
    const {
      saveDisabled,
      onSave,
      onSaveVersion,
      onSaveAs,
      isNewViz,
      isReadOnlyUser,
      canSaveVersion,
    } = this.props;

    const isSaveIconDisabled = (isNewViz && saveDisabled) || isReadOnlyUser;
    const isSaveVersionDisabled = !canSaveVersion;
    return (
      <ToolbarDropdownRoot className='save-as-dropdown'>
        <span
          title={messages.discoveryToolbar.saveTooltip}
          className={classnames('dropdown-save-icon', {
            disabled: saveDisabled,
          })}
          onClick={e => {
            if (!saveDisabled) {
              e.stopPropagation();
              onSave();
            }
          }}
        >
          <SaveIconWithPointerControl
            isSaveIconDisabled={isSaveIconDisabled}
            hover={!saveDisabled}
            disabled={saveDisabled}
          />
        </span>
        <IconDropdown
          id={'save-as-dropdown'}
          onOpen={() => {
            this.setState({ isMenuOpen: true });
          }}
          onClose={() => {
            this.setState({ isMenuOpen: false });
          }}
          iconSize={12}
          IconComponent={DownChevron}
        >
          <DisableClickMenuItem
            key={'save'}
            disabled={saveDisabled}
            onClick={onSave}
          >
            {messages.discoveryToolbar.saveMenuItem}
          </DisableClickMenuItem>
          <DisableClickMenuItem
            key={'saveVersion'}
            disabled={isSaveVersionDisabled}
            onClick={onSaveVersion}
          >
            {messages.discoveryToolbar.saveVersionMenuItem}
          </DisableClickMenuItem>
          <DisableClickMenuItem
            key={'saveAs'}
            disabled={isNewViz}
            onClick={onSaveAs}
          >
            {messages.discoveryToolbar.saveAsMenuItem}
          </DisableClickMenuItem>
        </IconDropdown>
      </ToolbarDropdownRoot>
    );
  }
}

export const ExportImageMenuItem = ({
  exportEnabled,
  onDownload,
  vizIsEmpty,
  chartIsValid,
  shouldExportAsExcel = false,
}) => {
  const disabled =
    vizIsEmpty || !exportEnabled || !chartIsValid || shouldExportAsExcel;
  return (
    <DisableClickMenuItem
      id={'image-export-menu-item'}
      key='download'
      title={
        !exportEnabled ? messages.discoveryToolbar.enableLiveQuery : undefined
      }
      onClick={onDownload}
      disabled={disabled}
    >
      {messages.discoveryToolbar.exportAsImageMenuItem}
    </DisableClickMenuItem>
  );
};

export const ExportExcelMenuItem = ({
  exportEnabled,
  vizId,
  vizIsEmpty,
  chartIsValid,
  chartType,
}) => {
  const { exportToExcel = _.noop, workerId } = useExportToExcel({
    vizId,
  });

  const { getWorkerMeta } = useContext(WorkerRefContext);
  const { isExporting: excelReportExporting = false } = getWorkerMeta(workerId);
  const excelExportDisabledReportTypes = [
    ChartSpecs.funnel.id,
    ChartSpecs.pipeline_changes.id,
    ChartSpecs.waterfall.id,
  ];
  const disabled =
    vizIsEmpty ||
    includes(excelExportDisabledReportTypes, chartType) ||
    !chartIsValid ||
    !exportEnabled ||
    excelReportExporting;
  return (
    <DisableClickMenuItem
      id='excel-export-menu-item'
      key='export'
      title={
        !exportEnabled ? messages.discoveryToolbar.enableLiveQuery : undefined
      }
      onClick={exportToExcel}
      disabled={disabled}
    >
      {messages.formatString(
        messages.discoveryToolbar.exportToExcelMenuItem,
        messages.nonTranslated.excel,
      )}
    </DisableClickMenuItem>
  );
};

class ShareDropdownClass extends PureComponent<{
  onCopyLink: () => void;
  onChangeAccess: (accessType: string) => void;
  onDownload: () => void;
  vizId: string;
  isDisabled: boolean;
  chartIsValid: boolean;
  isNewViz: boolean;
  isPrivate: boolean;
  isUserOwner: boolean;
  vizIsEmpty: boolean;
  exportEnabled: boolean;
  theme?: IDiscoverEmotionTheme;
  shouldExportAsExcel?: boolean;
  chartType: string;
}> {
  state;

  constructor(props) {
    super(props);
  }
  render() {
    const {
      onCopyLink,
      onChangeAccess,
      onDownload,
      isDisabled,
      chartIsValid,
      isNewViz,
      isPrivate: isDropdownPrivate,
      isUserOwner,
      vizIsEmpty,
      exportEnabled,
      shouldExportAsExcel = false,
    } = this.props;

    return (
      <ToolbarDropdownRoot className='share-dropdown'>
        <ShareIcon disabled={isDisabled} />
        <IconDropdown
          id={'share-dropdown-dropdown'}
          onOpen={() => {
            this.setState({ isMenuOpen: true });
          }}
          onClose={() => {
            this.setState({ isMenuOpen: false });
          }}
          iconSize={12}
          IconComponent={DownChevron}
        >
          <DisableClickMenuItem
            key={'copyLink'}
            onClick={onCopyLink}
            disabled={isNewViz}
          >
            {messages.discoveryToolbar.copyLinkMenuItem}
          </DisableClickMenuItem>
          <ExportExcelMenuItem
            exportEnabled={exportEnabled}
            chartIsValid={chartIsValid}
            vizId={this.props.vizId}
            vizIsEmpty={vizIsEmpty}
            chartType={this.props.chartType}
          />
          <ExportImageMenuItem
            exportEnabled={exportEnabled}
            chartIsValid={chartIsValid}
            onDownload={onDownload}
            vizIsEmpty={vizIsEmpty}
            shouldExportAsExcel={shouldExportAsExcel}
          />
          {isUserOwner && [
            <Divider key={'divider'} />,
            <DisableClickMenuItem
              key='private'
              onClick={() => onChangeAccess('private')}
              disabled={!chartIsValid}
            >
              {isDropdownPrivate && (
                <CheckIconSmall color={this.props.theme?.colors?.Gray90} />
              )}{' '}
              {messages.formatString(
                messages.discoveryToolbar.privateMenuOption,
                <small>- {messages.discoveryToolbar.privateSubText}</small>,
              )}
            </DisableClickMenuItem>,
            <DisableClickMenuItem
              key='public'
              onClick={() => onChangeAccess('public')}
              disabled={!chartIsValid}
            >
              {!isDropdownPrivate && (
                <CheckIconSmall color={this.props.theme?.colors?.Gray90} />
              )}{' '}
              {messages.formatString(
                messages.discoveryToolbar.publicMenuOption,
                <small>- {messages.discoveryToolbar.publicSubText}</small>,
              )}
            </DisableClickMenuItem>,
          ]}
        </IconDropdown>
      </ToolbarDropdownRoot>
    );
  }
}

export const ShareDropdown: typeof ShareDropdownClass = withTheme(
  ShareDropdownClass as any,
) as any;

export class DiscoveryToolbarUnconnected extends Component<
  IDiscoveryToolbarProps,
  IDiscoveryToolbarState
> {
  state: IDiscoveryToolbarState;

  constructor(props) {
    super(props);

    const {
      useFiscalCalendar,
      useLiveQuery,
      isPinned,
      hasFiscalCalendarSetting,
    } = props;

    this.state = {
      isPinned,
      hasFiscalCalendarSetting,
      useFiscalCalendar,
      useLiveQuery,
      showLiveQueryToggle: true,
      shownInitialLiveQueryNotification: false,
      allowExports: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const stateListenIds = [
      'useFiscalCalendar',
      'useLiveQuery',
      'showLiveQueryNotification',
      'showLiveQueryToggle',
    ];
    return (
      !_.isEqual(nextProps, this.props) ||
      _.some(
        stateListenIds,
        _varName => !_.isEqual(nextState[_varName], this.state[_varName]),
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.id !== prevProps.id &&
      this.props.isPinned !== prevProps.isPinned
    ) {
      // if the current viz has changed, reset the state
      this.setState({ isPinned: this.props.isPinned });
    }

    // undo/redo can change useFiscalCalendar
    const { useFiscalCalendar, useLiveQuery } = this.props;
    if (
      !_.isNil(useFiscalCalendar) &&
      this.state.useFiscalCalendar !== useFiscalCalendar
    ) {
      this.setState({ useFiscalCalendar });
    }
    if (!_.isNil(useLiveQuery) && this.state.useLiveQuery !== useLiveQuery) {
      this.setState({ useLiveQuery });
    }

    // At the time of this code block authorization, there was a rendering issue related to the modal
    // presentation and the toggle's position displacement. See DSC-5588
    if (this.state.showLiveQueryToggle !== prevState.showLiveQueryToggle) {
      this.setState({ showLiveQueryToggle: true }, () => this.forceUpdate());
    }

    if (
      prevState.showLiveQueryNotification !=
        this.state.showLiveQueryNotification &&
      this.state.showLiveQueryNotification
    ) {
      this.props.showToast({
        label: messages.toast.notice,
        onClose: () => {
          this.setState({
            showLiveQueryNotification: false,
          });
        },
        children: (
          <span>{messages.discoveryToolbar.liveQueryDialogMessage}</span>
        ),
      });
    }

    const { discovery } = this.props;
    const { discovery: prevDiscovery } = prevProps;
    if (
      !_.isNil(discovery.viz.revisionSelected) &&
      prevDiscovery.viz.revisionSelected !== discovery.viz.revisionSelected
    ) {
      const { updatedByName, updatedOn } = discovery.viz.revisionSelected;
      const formattedDate = moment(updatedOn).format(this.props.dateFormat);
      const formattedTime = moment(updatedOn).format(this.props.timeFormat);
      this.props.showToast({
        label: messages.toast.notice,
        children: (
          <span>
            {messages.formatString(
              messages.discoveryToolbar.previousVersionToast,
              updatedByName,
              formattedDate,
              formattedTime,
            )}
          </span>
        ),
      });
    }
  }

  activateLeftPanel(key) {
    this.props.setConfigPanelDetail(this.props.discovery, key);
  }
  activateRightPanel(key) {
    this.props.setPanelDetail(this.props.discovery, key);
  }
  doAction(action: string) {
    const { discovery } = this.props;
    switch (action) {
      case 'save':
        this.props.onSave();
        break;
      case 'save-version':
        this.props.onSaveVersion();
        break;
      case 'undo':
        this.props.undo(discovery);
        break;
      case 'redo':
        this.props.redo(discovery);
        break;
      case 'refresh':
        this.props.refresh(discovery);
        break;
      case 'share':
        this.props.share(discovery);
        break;
      case 'public':
        this.props.setVizAccess(discovery, 'public');
        break;
      case 'private':
        this.props.setVizAccess(discovery, 'private');
        break;
      case 'save-as': {
        this.props.onSaveAs();
        break;
      }
      case 'download': {
        Viz.generateChartScreenshot(discovery.name, this.props.theme);
        break;
      }
      case 'pin': {
        let pinned = _.get(this.props, 'userPreferences.pinnedDiscoveries', []);
        let isPinned = false;
        if (this.props.isPinned) {
          pinned = pinned.filter(id => id !== this.props.id);
        } else {
          pinned = [...pinned, this.props.id];
          isPinned = true;
        }
        this.setState({ isPinned });
        this.props.updateUserPreferences({
          pinnedDiscoveries: pinned,
        });
        break;
      }
      case 'fiscalCalendarToggle': {
        const useFiscalCalendar = !this.state?.useFiscalCalendar;

        this.setState({ useFiscalCalendar });
        this.props.setUsingFiscalCalendar(discovery, useFiscalCalendar);
        this.props.setDirtyViz(this.props.id, true);
        break;
      }
      case 'liveQueryToggle': {
        const useLiveQuery = !this.state?.useLiveQuery;

        this.setState({
          useLiveQuery,
          showLiveQueryNotification: !useLiveQuery,
        });
        this.props.setUsingLiveQuery(discovery, useLiveQuery);
        this.props.setDirtyViz(this.props.id, true);
        break;
      }
    }
  }

  // At the time of this code block authorization, there was a rendering issue related to the modal
  // presentation and the toggle's position displacement. See DSC-5588
  forceSwitchRerender() {
    this.setState({
      showLiveQueryToggle: false,
    });
  }

  renderIconItem({ name, tooltipText, hide, icon, disabled }) {
    return hide ? (
      []
    ) : (
      <NavItem
        id={`discovery-tool-bar-action-${_.kebabCase(name)}`}
        key={name}
        eventKey={name}
        disabled={disabled || false}
      >
        <div className='vertical-center'>
          <span title={tooltipText}>{icon}</span>
        </div>
      </NavItem>
    );
  }

  render() {
    const { isPinned } = this.state;

    const {
      hasUndo,
      hasRedo,
      dirty,
      isPrivate,
      isNewViz,
      isUserOwner,
      hasInsights,
      insightsLoading,
      discovery,
      isMobile,
      queryResults,
      chartSpec,
      exportEnabled,
      chartIsValid,
    } = this.props;

    const shouldExportAsExcel = chartSpec.shouldDeferToExport(queryResults);

    const isRefreshDisabled = _.isEmpty(queryResults) || !chartIsValid;
    const isSavePanelDisabled = !dirty || !discovery?.canUpdate;
    const isSharedDisabled = (isNewViz || isPrivate) && !isUserOwner;

    const actionPanelItems = _.map(
      [
        {
          name: 'undo',
          tooltipText: messages.discoveryToolbar.undoTooltip,
          disabled: !hasUndo,
          icon: <UndoIcon hover={hasUndo} disabled={!hasUndo} />,
        },
        {
          name: 'redo',
          tooltipText: messages.discoveryToolbar.redoTooltip,
          disabled: !hasRedo,
          icon: <RedoIcon hover={hasRedo} disabled={!hasRedo} />,
        },
        {
          name: 'refresh',
          tooltipText: messages.datasets.refreshMenuItem,
          disabled: isRefreshDisabled,
          icon: (
            <RefreshIcon
              hover={!isRefreshDisabled}
              disabled={isRefreshDisabled}
            />
          ),
        },
      ],
      this.renderIconItem,
    );

    const pinPanelItems = _.map(
      [
        {
          name: 'pin',
          tooltipText: isPinned
            ? messages.discoveryToolbar.unpinTooltip
            : messages.discoveryToolbar.pinTooltip,
          disabled: isNewViz,
          icon: <PinIcon hover disabled={isNewViz} pinned={isPinned} />,
        },
      ],
      this.renderIconItem,
    );

    const leftPanelItems = _([
      {
        name: 'layout',
        icon: <LayoutIcon />,
        tooltipText: messages.discoveryToolbar.layoutPanelIconTooltip,
      },
      {
        name: 'format',
        icon: <FormatIcon />,
        tooltipText: messages.discoveryToolbar.formatPanelIconTooltip,
      },
      {
        name: 'detail',
        icon: <InfoIconLg iconSize={'md'} />,
        tooltipText: messages.discoveryToolbar.detailPanelIconTooltip,
      },
    ])
      .intersectionBy(this.props.allowedTabs, val =>
        _.isString(val) ? val : val?.name,
      )
      .map(this.renderIconItem)
      .value();

    const rightPanelItems = isMobile
      ? []
      : _.map(
          [
            {
              name: Labels.DISCOVERY_INSIGHTS,
              disabled: !hasInsights,
              icon: (
                <InsightsIcon
                  size={16}
                  animate={insightsLoading}
                  disabled={!hasInsights}
                  hasNewInsights={
                    this.props.hasInsights &&
                    this.props.interactivePanelDetail !==
                      Labels.DISCOVERY_INSIGHTS &&
                    !this.props.insightsChecked
                  }
                />
              ),
              tooltipText: getInsightsTooltip({ hasInsights, insightsLoading }),
            },
          ].filter(Boolean),
          this.renderIconItem,
        );

    const minOffsetMargin = leftPanelItems.length * 56;
    const currentLeftPanelWidth = this.props.configPanelDetail
      ? this.props.leftPanelWidth
      : 0;

    const leftOffset =
      currentLeftPanelWidth > minOffsetMargin
        ? this.props.leftPanelWidth - minOffsetMargin
        : 0;

    return (
      <DiscoveryToolbarNavbar id='discovery-toolbar'>
        <Nav
          id='discovery-toolbar-left'
          activeKey={this.props.configPanelDetail || null}
          onSelect={key => this.activateLeftPanel(key)}
        >
          {leftPanelItems}
        </Nav>
        <NavWithLeftMargin
          id='discovery-toolbar-fiscal-calendar'
          activeKey={null}
          leftOffset={leftOffset}
        >
          <NavItemFullWidth>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.props?.hasFiscalCalendarSetting && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id={'fiscalCalendarSwitch'}
                        checked={!!this.state?.useFiscalCalendar}
                        color={'primary'}
                        onClick={() => this.doAction('fiscalCalendarToggle')}
                        name={'fiscalCalendarToggleChecked'}
                      />
                    }
                    label={messages.discoveryToolbar.fiscalCalendar}
                    labelPlacement={'start'}
                  />
                </FormGroup>
              )}
              {this.state.showLiveQueryToggle && (
                <FormGroup
                  title={messages.discoveryToolbar.liveQueryHint}
                  id={'liveQuerySwitchParent'}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        id={'liveQuerySwitch'}
                        checked={!!this.state?.useLiveQuery}
                        color={'primary'}
                        onClick={() => this.doAction('liveQueryToggle')}
                        name={'liveQueryToggleChecked'}
                      />
                    }
                    label={messages.discoveryToolbar.liveQueryTitle}
                    labelPlacement={'start'}
                  />
                </FormGroup>
              )}
            </div>
          </NavItemFullWidth>
        </NavWithLeftMargin>

        <ToolbarRightNav
          id='discovery-toolbar-right'
          pullRight
          activeKey={this.props.interactivePanelDetail || null}
          onSelect={key => this.activateRightPanel(key)}
        >
          {rightPanelItems}
        </ToolbarRightNav>

        <ActionItemRightNav
          id='discovery-toolbar-actions'
          pullRight
          onSelect={action => this.doAction(action)}
        >
          {actionPanelItems}

          {!this.props.isReadOnlyUser && (
            <SaveDropdownStyles className='panel-icon-dropdown'>
              <SaveAsDropdown
                isReadOnlyUser={this.props.isReadOnlyUser}
                saveDisabled={isSavePanelDisabled}
                onSave={() => this.doAction('save')}
                onSaveVersion={() => this.doAction('save-version')}
                onSaveAs={() => this.doAction('save-as')}
                isNewViz={this.props.isNewViz}
                isDirty={this.props.dirty}
                isUserOwner={this.props.isUserOwner}
                canSaveVersion={this.props.canSaveVersion}
              />
            </SaveDropdownStyles>
          )}

          <li className={'panel-icon-dropdown'}>
            <ShareDropdown
              exportEnabled={exportEnabled}
              vizIsEmpty={this.props.vizIsEmpty}
              onCopyLink={() => this.doAction('share')}
              onDownload={() => this.doAction('download')}
              vizId={this.props.id}
              onChangeAccess={access => this.doAction(access)}
              chartIsValid={this.props.chartIsValid}
              isNewViz={this.props.isNewViz}
              isPrivate={this.props.isPrivate}
              isUserOwner={this.props.isUserOwner}
              isDisabled={!this.props.chartIsValid && isSharedDisabled}
              shouldExportAsExcel={shouldExportAsExcel}
              chartType={this.props.viz.chartType}
            />
          </li>

          {pinPanelItems}
        </ActionItemRightNav>
      </DiscoveryToolbarNavbar>
    );
  }
}

const isPinnedSelector: (state: any, props?: any) => any = createSelector(
  [VIZ_SELECTORS.getDiscoveryId, ACCOUNT_SELECTORS.getPinnedDiscoveries],
  (id, pinned: string[]) => {
    if (_.isEmpty(pinned)) {
      return false;
    } else {
      return pinned.some(pinnedId => pinnedId === id);
    }
  },
);

const mapStateToProps = (state, ownProps): IDiscoveryToolbarMapStateProps => {
  const discovery =
    state.discover.openDiscoveries[state.discover.displayDiscovery];
  const { vizIsEmpty } = state.discover;
  const open = discovery.present;
  const { dirty = false } = open;
  let { hasInsights = false, hasAdvancedInsights = false } = open || {};
  const chartSpec = ChartSpecs[open.viz.chartType];
  const chartIsValid = chartSpec.validate(open.viz).valid;
  const isAdvancedMode = state.main.advanced;
  hasInsights = (isAdvancedMode && hasAdvancedInsights) || hasInsights;
  const useFiscalCalendar =
    (VIZ_SELECTORS.getActiveVizFiscalSetting as any)(state, ownProps) ===
    'true';
  const useLiveQuery =
    (VIZ_SELECTORS.getLiveQueryOption as any)(state, ownProps) === 'true';

  const leftPanelWidth = (VIZ_SELECTORS.getActiveVizPanelWidth as any)(
    state,
    ownProps,
  );
  const { dateFormat, timeFormat } = ACCOUNT_SELECTORS.getDatetimeFormats(
    state,
  );
  const { i18nPrefs = {} } = state?.account?.currentUser;
  const configPanelDetail = getConfigPanelDetail(state, {
    discoveryId: discovery?.id,
  });
  const isNewViz = !!open?.id?.includes('newViz');
  return {
    dateFormat,
    timeFormat,
    discovery: open,
    chartSpec,
    allowedTabs: getAllowedTabs(state),
    configPanelDetail: open ? configPanelDetail : null,
    interactivePanelDetail: open ? open.viz.options.panelDetail : null,
    id: state.discover.displayDiscovery,
    hasUndo:
      open &&
      open.discoveryType === 'VISUALIZATION' &&
      discovery.past?.length > 0,
    hasRedo:
      open &&
      open.discoveryType === 'VISUALIZATION' &&
      discovery.future?.length > 0,
    resetCheckpoint: _.isNil(discovery.saveCheckpoint)
      ? 0
      : discovery.saveCheckpoint,
    hasInsights,
    isMobile: state.main.isMobile,
    useLiveQuery,
    vizIsEmpty,
    insightsChecked: open.insightsChecked,
    insightsLoading: open ? open.insightsLoading : false,
    chartIsValid,
    isNewViz,
    isAdmin: isAdmin(state.account),
    isPrivate: isPrivate(state),
    isReadOnlyUser: getIsReadOnlyUser(state),
    isUserOwner: isUserOwnerOfDiscoveryViz(state) || isAdmin(state.account),
    isAdvancedMode,
    userPreferences: (ACCOUNT_SELECTORS.getUserPreferences as any)(
      state,
      ownProps,
    ),
    isPinned: isPinnedSelector(state, {}),
    viz: open.viz,
    queryResults: _.get(open, 'vizQueryResults.data'),
    hasFiscalCalendarSetting: (VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting as any)(
      state,
      ownProps,
    ),
    leftPanelWidth,
    useFiscalCalendar,
    i18nPrefs,
    dirty,
    canSaveVersion:
      !isNewViz &&
      open.canUpdate &&
      (dirty ||
        _.isEmpty(open?.viz?.revisions) ||
        !_.isEqual(
          (_.head(open?.viz?.revisions) as IRevision)?.updatedOn,
          open?.viz?.updatedOn,
        )),
  };
};
let toastTimer;
const mapDispatchToProps = (
  dispatch,
): IDiscoveryToolbarDispatchProps & IUserPreferencesMutationInputProps => {
  return {
    setPanelDetail: (discovery, panelKey) => {
      dispatch(Discover.setPanelDetail(discovery?.id, panelKey));
    },
    setConfigPanelDetail: (discovery, panelKey) => {
      dispatch(Discover.setConfigPanelDetail(discovery?.id, panelKey));
    },
    share: discovery => {
      dispatch(MainActions.copyToClipboard(window.location.href));
      dispatch(
        MainActions.showToast({
          text: messages.linkCopied,
          id: discovery?.id,
        }),
      );
      clearTimeout(toastTimer);
      toastTimer = setTimeout(() => {
        dispatch(MainActions.closeToast());
      }, 2000);
    },
    undo: discovery => {
      dispatch(Discover.undoDiscovery(discovery?.id));
    },
    redo: discovery => {
      dispatch(Discover.redoDiscovery(discovery?.id));
    },
    refresh: discovery => {
      const variables = Viz.buildQueryVariables(
        discovery.viz,
        ChartSpecs[discovery?.viz?.chartType],
      );
      const {
        id,
        monitorEventId,
        viz: { name: sourceName = undefined } = {},
      } = discovery;

      dispatch(
        Discover.vizQuery({
          id,
          sourceName,
          monitorEventId,
          variables: {
            ...variables,
            bypassCache: true,
          },
          queryId: discovery?.viz?.queryId,
        }),
      );
    },
    setVizAccess: (discovery, access) => {
      dispatch(Discover.setVizAccess(discovery?.id, access));
    },
    refreshUserPreferences: userPrefs => {
      dispatch(Account.setCurrentUserPreferences(userPrefs));
    },
    setUsingFiscalCalendar: (discovery, useFiscalCalendar) => {
      dispatch(
        Discover.setSettingForViz({
          id: discovery?.id,
          setting: USE_FISCAL_REPORTING,
          value: useFiscalCalendar,
        }),
      );
    },
    setUsingLiveQuery: (discovery, useLiveQuery) => {
      dispatch(
        Discover.setLiveQueryForViz({
          id: discovery?.id,
          value: useLiveQuery,
        }),
      );
    },
    setDirtyViz: (vizId, dirty = true) => {
      dispatch(Discover.setDiscoveryDirty(vizId, dirty));
    },
  };
};

export interface ICreateTargetMutationProps {
  createTarget: any;
}

export const DiscoveryToolbar = compose(
  ToastHOC,
  connect(mapStateToProps, mapDispatchToProps),
  UserPreferencesMutation,
  withTheme,
)(DiscoveryToolbarUnconnected as any) as ComponentClass<
  IDiscoveryToolbarOwnProps
>;

function getInsightsTooltip(props) {
  const { hasInsights, insightsLoading } = props;
  if (!hasInsights) {
    return messages.discoveryToolbar.noInsightsPanelTooltip;
  }
  if (insightsLoading) {
    return messages.discoveryToolbar.insightsLoadingPanelTooltip;
  }
  return _.get(messages, Labels.DISCOVERY_INSIGHTS, Labels.DISCOVERY_INSIGHTS);
}
